import './App.css';
import './assets/css/fonts.css'
import LandingIntro from "./components/intro/LandingIntro";

function App() {
    return (
        <div className="App">
            <LandingIntro/>
        </div>
    );
}

export default App;
